import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { Roles } from "../constants/RolesConstants";
import { useUser } from "./UserContext";

type RolesProviderProps = {
  children?: ReactNode;
};

type RolesContextType = {
  isBasicUser: boolean;
  isBocUser: boolean;
  isIntlResourceCreator: boolean;
  isInviter: boolean;
  isIntlDocsViewer: boolean;
  isIntlAdmin: boolean;
  canResetPasswords: boolean;
  hasCalculatedRoles: boolean;
};

const RolesContext = createContext<RolesContextType | undefined>(undefined);

export const BOC_TEAM_ID = 1;

const RolesProvider: React.FC<RolesProviderProps> = ({ children }) => {
  const { userInfo } = useUser();
  const [isBasicUser, setIsBasicUser] = useState<boolean>(false);
  const [isBocUser, setIsBocUser] = useState<boolean>(false);
  const [isIntlResourceCreator, setIsIntlResourceCreator] = useState<boolean>(false);
  const [isInviter, setIsInviter] = useState<boolean>(false);
  const [isIntlDocsViewer, setIsIntlDocsViewer] = useState<boolean>(false);
  const [isIntlAdmin, setIsIntlAdmin] = useState<boolean>(false);
  const [canResetPasswords, setCanResetPasswords] = useState<boolean>(false);
  const [hasCalculatedRoles, setHasCalculatedRoles] = useState<boolean>(false);

  const checkForRoles = (roles: string[]) => {
    setIsBasicUser(roles.includes(Roles.IPL_USER));
    setIsInviter(roles.includes(Roles.IPL_INVITER));
    setIsIntlResourceCreator(roles.includes(Roles.IPL_INTL_RESOURCE_CREATOR));
    setIsIntlDocsViewer(roles.includes(Roles.IPL_INTL_DOCS_VIEWER));
    setIsIntlAdmin(roles.includes(Roles.IPL_INTL_ADMIN));
    setCanResetPasswords(roles.includes(Roles.IPL_PASSWORD_RESETTER));
    setIsBocUser(userInfo.organizationId === BOC_TEAM_ID);
  };

  useEffect(() => {
    if (!!userInfo) {
      checkForRoles(userInfo.authorities.map((role) => role.authority));
      setHasCalculatedRoles(true);
    }
  }, [userInfo]);

  return (
    <RolesContext.Provider
      value={{
        isBasicUser,
        isBocUser,
        isIntlResourceCreator,
        isInviter,
        isIntlDocsViewer,
        isIntlAdmin,
        canResetPasswords,
        hasCalculatedRoles,
      }}
    >
      {children}
    </RolesContext.Provider>
  );
};

const useRoles = (): RolesContextType => {
  const rolesContext: RolesContextType = useContext<RolesContextType>(RolesContext);
  if (rolesContext === undefined) {
    throw new Error(`useRoles must be used within a RolesProvider`);
  }
  return rolesContext;
};

export { RolesContext, RolesProvider, useRoles };
